<template>
  <h2 class="title">{{ text }}</h2>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    text: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 22px;
  text-align: center;
  padding: 40px 0 35px;
}
</style>
