<template>
  <div @mouseover="displayNavBackground" @mouseleave="removeNavBackground">
    <swiper ref="mySwiper"
      :options="swiperOptions"
      class="slider"
      v-if="items.length">
      <swiper-slide v-for="(item, index) in items" :key="index">
        <a :href="switchItemURL(item)">
          <div v-if="rank" class="rank">{{ index + 1 }}</div>
          <img
            :src="`https://thumbnail.image.rakuten.co.jp/@0_mall/auc-rodeo/cabinet/${item.image}-0.jpg`"
            alt=""
            class="img">
          <div class="brand">{{ item.brand }}</div>
          <div class="price">{{ taxIn(item.price) }}</div>
        </a>
      </swiper-slide>
      <div class="swiper-pagination"  slot="pagination"></div>
      <div
        class="swiper-button-prev"
        slot="button-prev"
        :class="{ 'swiper-button-rank': rank, 'swiper-button-hover': swiperMouseOver }">
        <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
      </div>
      <div
        class="swiper-button-next"
        slot="button-next"
        :class="{ 'swiper-button-rank': rank, 'swiper-button-hover': swiperMouseOver }">
        <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
      </div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: 'ItemSlider',
  data () {
    return {
      swiperMouseOver: false
    }
  },
  methods: {
    displayNavBackground () {
      this.swiperMouseOver = true
    },
    removeNavBackground () {
      this.swiperMouseOver = false
    }
  },
  computed: {
    swiperOptions () {
      return {
        ...this.$store.state.swiperCommonOptions,
        speed: 800,
        slidesPerView: 5,
        spaceBetween: 20,
        slidesPerGroup: 5,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  props: {
    items: {
      type: Array,
      require: true
    },
    rank: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 1200px;

  .rank {
    text-align: center;
    font-size: 17px;
    padding-bottom: 14px;
  }

  a {
    transition: .15s;

    &:hover {
      opacity: .6;
    }
  }

  img {
    width: 100%;
  }

  .brand {
    text-align: center;
    font-size: 13px;
    padding: 18px 0 6px;
  }

  .price {
    text-align: center;
    font-size: 13px;
    padding-bottom: 40px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 34%;

    &.swiper-button-rank {
      top: 39.1%;
    }
  }
}
</style>
