<template>
  <div class="wrap minContainer">
    <swiper
      ref="mySwiper"
      :options="mainSwiperOptions"
      class="mainSlider"
      v-if="mainSlideItems.length">
      <swiper-slide v-for="(item, index) in mainSlideItems" :key="index">
        <a :href="item.link[$store.state.hostName]" class="link">
          <div class="left">
              <div class="brand">{{ item.brand }}</div>
              <div class="text">{{ item.text }}</div>
              <div class="button">Shop Now</div>
          </div>
          <div class="right">
              <img
                :src="item.img"
                alt=""
                class="img">
          </div>
        </a>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev">
        <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
      </div>
    </swiper>

    <div @mouseover="displayNavBackground" @mouseleave="removeNavBackground">
      <swiper
        ref="mySwiper"
        :options="subSwiperOptions"
        class="subSlider"
        v-if="subSlideItems.length">
        <swiper-slide v-for="(item, index) in subSlideItems" :key="index">
          <a :href="item.link[$store.state.hostName]" class="link">
            <img
              :src="item.img"
              alt=""
              class="img">
          </a>
        </swiper-slide>
        <div
          class="swiper-button-prev"
          :class="{ 'swiper-button-hover': swiperMouseOver }"
          slot="button-prev">
          <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
        </div>
        <div
          class="swiper-button-next"
          :class="{ 'swiper-button-hover': swiperMouseOver }"
          slot="button-next">
          <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ranking',
  data () {
    return {
      swiperMouseOver: false
    }
  },
  methods: {
    displayNavBackground () {
      this.swiperMouseOver = true
    },
    removeNavBackground () {
      this.swiperMouseOver = false
    }
  },
  computed: {
    mainSwiperOptions () {
      return {
        ...this.$store.state.swiperCommonOptions,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true
        }
      }
    },
    subSwiperOptions () {
      return {
        ...this.$store.state.swiperCommonOptions,
        slidesPerView: 4,
        spaceBetween: 20,
        slidesPerGroup: 1,
        loopFillGroupWithBlank: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        }
      }
    },
    mainSlideItems () {
      const self = this
      const bannerJson = this.$store.state.json.banner
      let rtn = []

      if (!Object.keys(bannerJson).length) return rtn

      // 並び順データを取得
      const order = bannerJson.order.pcMain
      rtn = order.map(brandName => {
        const brandInfo = bannerJson.database[brandName]
        brandInfo.brand = brandName // ブランド名のキーを追加
        return brandInfo
      })

      // configがonになっていないスライドを削除
      return rtn.filter(item => item.config[self.$store.state.hostName] === 'on')
    },
    subSlideItems () {
      const self = this
      const bannerJson = this.$store.state.json.banner
      let rtn = []

      if (!Object.keys(bannerJson).length) return rtn

      // 並び順データを取得
      const order = bannerJson.order.pcSub
      rtn = order.map(brandName => {
        const brandInfo = bannerJson.database[brandName]
        brandInfo.brand = brandName // ブランド名のキーを追加
        return brandInfo
      })

      // configがonになっていないスライドを削除
      return rtn.filter(item => item.config[self.$store.state.hostName] === 'on')
    },
    newArrival () {
      return this.$store.state.json.new
    },
    hotItems () {
      const rtn = []
      for (let i = 0; i < this.newArrival.length; i++) {
        if (this.newArrival[i].hot === 1) {
          rtn.push(this.newArrival[i])
        }
        if (rtn.length === 20) break
      }
      return rtn
    },
    reArrival () {
      const rtn = []
      for (let i = 0; i < this.newArrival.length; i++) {
        if (this.newArrival[i].group === '再入荷') {
          rtn.push(this.newArrival[i])
        }
        if (rtn.length === 20) break
      }
      return rtn
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  margin: 80px auto 80px;
  max-width: 1200px;
}

.mainSlider {
  margin-bottom: 50px;

  .link {
    display: flex;
  }

  .left {
    width: 56.3%;
    padding-left: 9%;
    box-sizing: border-box;
  }

  .brand {
    font-size: 18px;
    margin-top: 140px;
  }

  .text {
    font-size: 27px;
    margin-top: 15px;
  }

  .button {
    border: 1px solid #000;
    display: inline-block;
    font-size: 16px;
    padding: 14px 36px;
    border-radius: 150px;
    margin-top: 28px;
    transition: .3s;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }

  .right {
    width: 43.7%;
  }

  .img {
    max-width: 404px;
    width: 100%;
  }
}

.subSlider {
  .img {
    width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  top: calc(50% - 18px)
}
</style>
