<template>
  <div class="wrap">
    <SectionTitle text="Category"/>

    <div
      class="minContainer"
      @mouseover="displayNavBackground"
      @mouseleave="removeNavBackground">
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        class="slider"
        v-if="slideItems.length">
        <swiper-slide v-for="(item, index) in slideItems" :key="index">
          <a
            :href="e.link[$store.state.hostName]"
            class="link"
            v-for="(e, i) in item"
            :key="i"
            v-show="e.link[$store.state.hostName]">
            <img
              :src="e.img"
              alt=""
              class="img">
            <div class="text">{{ e.text }}</div>
          </a>
        </swiper-slide>
        <div class="swiper-pagination"  slot="pagination"></div>
        <div
          class="swiper-button-prev"
          :class="{ 'swiper-button-hover': swiperMouseOver }"
          slot="button-prev">
          <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
        </div>
        <div
          class="swiper-button-next"
          :class="{ 'swiper-button-hover': swiperMouseOver }"
          slot="button-next">
          <svg width="13" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M.86 39.7l-.85-.53 11.84-19.32L0 .52.85 0l12.16 19.84-.15.27z" fill="#1A1A1A" fill-rule="nonzero"></path></svg>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'

export default {
  name: 'Category',
  data () {
    return {
      swiperMouseOver: false,
      slideItems: [
        [
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/bottoms.jpg',
            text: 'Bottoms',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002707/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000200/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/bottoms5ba6.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/bottoms5ba6.html',
              O: 'https://www.aun-wa.com/SHOP/411347/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83p%83%93%83c&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/outer.jpg',
            text: 'Outer',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002709/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000202/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/outer5ba5a18.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/outer5ba5a16.html',
              O: 'https://www.aun-wa.com/SHOP/411844/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83W%83%83%83P%83b%83g&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/tops.jpg',
            text: 'Tops',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002708/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000201/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/tops5ba5c89.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/tops5ba5c821.html',
              O: 'https://www.aun-wa.com/SHOP/411534/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83V%83%83%83c&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/footwear.jpg',
            text: 'Footwear',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002704/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000363/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/footwear5b14.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/footwear5b14.html',
              O: 'https://www.aun-wa.com/SHOP/411912/412146/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%8CC&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/bag.jpg',
            text: 'Bag',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002703/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000362/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/bag5ba5d0a10.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/bag5ba5d0a10.html',
              O: 'https://www.aun-wa.com/SHOP/411912/411917/list.html',
              W: 'https://wowma.jp/bep/m/klist2?e_scope=O&user=24431709&keyword=%83o%83b%83O',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/cap.jpg',
            text: 'Cap',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002910/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000569/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/cap5ba5ada10.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/cap5ba5ada9.html',
              O: 'https://www.aun-wa.com/SHOP/411912/411913/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83L%83%83%83b%83v&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          }
        ],
        [
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/acessories.jpg',
            text: 'Acessories',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000000634/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000361/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/accessorie7.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/accessorie7.html',
              O: 'https://www.aun-wa.com/SHOP/411912/411924/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83A%83N%83Z%83T%83%8A%81%5B&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/shirts.jpg',
            text: 'Shirts',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002715/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000222/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/shirts5ba5.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/shirts5ba5.html',
              O: 'https://www.aun-wa.com/SHOP/411534/411804/list.html',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83V%83%83%83c&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/sweatshirts.jpg',
            text: 'Sweatshirts',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002729/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000376/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/sweatshirt.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/sweatshirt4.html',
              O: 'https://www.aun-wa.com/SHOP/411844/411866/list.html',
              W: 'https://wowma.jp/bep/m/klist2?e_scope=O&user=24431709&keyword=%83X%83E%83F%83b%83g',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/mens.jpg',
            text: 'Mens',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002705/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000102/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/mens5ba5e1.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/mens5ba5e15.html',
              O: '',
              W: '',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/women.jpg',
            text: 'Women',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002706/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000103/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/women5ba5e4.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/women5ba5e4.html',
              O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%83%AC%E3%83%87%E3%82%A3%E3%83%BC%E3%82%B9',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83%8C%83f%83B%81%5B%83X&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/junior-kids.jpg',
            text: 'Junior・Kids',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002772/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000422/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/juniora1a62.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/juniora1a62.html',
              O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%82%AD%E3%83%83%E3%82%BA',
              W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%83L%83b%83Y&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
              Q: ''
            }
          }
        ],
        [
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/lifestyle-goods.jpg',
            text: 'Lifestyle Goods',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000003049/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000703/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/lifestyleg.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/lifestyleg.html',
              O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%82%A4%E3%83%B3%E3%83%86%E3%83%AA%E3%82%A2',
              W: 'https://wowma.jp/bep/m/klist2?e_scope=O&user=24431709&keyword=%83O%83b%83Y',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/outdoor-gear.jpg',
            text: 'Outdoor Gear',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002795/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000452/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/outdoorgea2.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/outdoorgea2.html',
              O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%82%B7%E3%83%A5%E3%83%A9%E3%83%95',
              W: 'https://wowma.jp/bep/m/klist2?e_scope=O&user=24431709&keyword=%83A%83E%83g%83h%83A',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/souvenir-jacket.jpg',
            text: 'Souvenir Jacket',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002769/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000415/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/souvenirja.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/souvenirja.html',
              O: 'https://www.aun-wa.com/SHOP/411844/411845/list.html',
              W: 'https://wowma.jp/bep/m/klist2?e_scope=O&user=24431709&keyword=%83X%83J%83W%83%83%83%93',
              Q: ''
            }
          },
          {
            img: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/img/category/toys-games.jpg',
            text: 'Toys & Games',
            link: {
              R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000003050/',
              R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000704/',
              Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/toys26amp3.html',
              Y2: 'https://store.shopping.yahoo.co.jp/bros/toys26amp3.html',
              O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%81%8A%E3%82%82%E3%81%A1%E3%82%83',
              W: 'https://wowma.jp/bep/m/klist2?e_scope=O&user=24431709&keyword=%82%A8%82%E0%82%BF%82%E1',
              Q: ''
            }
          }
        ]
      ]
    }
  },
  methods: {
    displayNavBackground () {
      this.swiperMouseOver = true
    },
    removeNavBackground () {
      this.swiperMouseOver = false
    }
  },
  computed: {
    swiperOptions () {
      return {
        ...this.$store.state.swiperCommonOptions,
        spaceBetween: 30,
        speed: 800,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  components: {
    SectionTitle
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 70px 0 133px;
  background-color: #fafafa;
}

.slider {
  max-width: 1200px;

  .img {
    width: 100%;
  }

  .text {
    text-align: center;
    margin-top: 19px;
    font-size: 14px;
    padding-bottom: 40px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;

    .link {
      width: calc((100% - 150px) / 6);

      & + .link {
        margin-left: 30px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 33.3%
  }
}
</style>
