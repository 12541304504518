export default {
  methods: {
    /**
     * 数値を税込みに変更し、金額表示のフォーマットを整える。
     *
     * @param {number} price
     */
    taxIn (price) {
      let tmp = Math.floor(price * 1.1) + ''
      tmp = '¥ ' + tmp.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      return tmp
    },
    /**
     * 商品のURLを各サイト用に変更する。
     *
     * @param {object} itemInfo - 新着JSONの１商品のオブジェクト
     */
    switchItemURL (itemInfo) {
      switch (this.$store.state.hostName) {
        case 'R1':
          return `https://item.rakuten.co.jp/auc-rodeo/${itemInfo.number}/`

        case 'R2':
          return `https://item.rakuten.co.jp/rodeo-2nd/${itemInfo.number}/`

        case 'Y1':
          return `https://store.shopping.yahoo.co.jp/rodeobros/${itemInfo.number}.html`

        case 'Y2':
          return `https://store.shopping.yahoo.co.jp/bros/${itemInfo.number}.html`

        case 'O':
          return `https://www.aun-wa.com/SHOP/${itemInfo.number}.html`

        case 'W':
          return `https://wowma.jp/item/${itemInfo.wowma}`

        case 'Q':
          return ''

        default:
          return `https://item.rakuten.co.jp/auc-rodeo/${itemInfo.number}/`
      }
    },
    /**
     * URLを各サイト用に変更する。
     *
     * @param {object} partURL - 飛ばしたいURLの一部
     */
    switchURL (partURL) {
      switch (this.$store.state.hostName) {
        case 'R1':
          return `https://www.rakuten.ne.jp/gold/auc-rodeo/${partURL}`

        case 'R2':
          return `https://www.rakuten.ne.jp/gold/rodeo-2nd/${partURL}`

        case 'Y1':
          return `https://shopping.geocities.jp/rodeobros/${partURL}`

        case 'Y2':
          return `https://shopping.geocities.jp/bros/${partURL}`

        case 'O':
          return `https://www.aun-wa.com/${partURL}`

        case 'W':
          return `https://plus.wowma.jp/user/24431709/plus/${partURL}`

        case 'Q':
          return ''

        default:
          return `https://www.rakuten.ne.jp/gold/auc-rodeo/${partURL}`
      }
    },
    /**
     * クリック時に全てのaタグのtargetを_topにする
     */
    allTargetTop () {
      document.querySelectorAll('a').forEach(ele => {
        ele.onclick = function (aTag) {
          aTag.preventDefault()
          window.open(ele.href, '_top')
        }
      })
    }
  },
  mounted () {
    // Qoo10の場合、全てのページ遷移の時にtargetをtopにする
    if (this.$store.state.hostName === 'Q') {
      this.allTargetTop()
    }
  },
  updated () {
    // Qoo10の場合、全てのページ遷移の時にtargetをtopにする
    if (this.$store.state.hostName === 'Q') {
      this.allTargetTop()
    }
  }
}
