<template>
  <footer>
    <div class="footerWrap">
      <ul class="linklist">
        <li
          v-for="(link, index) in links"
          :key="link.title"
          v-show="!(index === 2 && $store.state.hostName.indexOf('R') !== -1)">
          <div class="title">{{ link.title }}</div>
          <ul class="links">
            <li
            v-for="l in link.linkList"
            :key="l.name"
            v-show="l.link[$store.state.hostName] !== ''">
              <a
                :href="l.link[$store.state.hostName]"
                v-if="l.link[$store.state.hostName] !== null">
                {{ l.name }}
              </a>
              <span v-else>
                {{ l.name }}
              </span>
            </li>
          </ul>
        </li>
      </ul>

      <div class="copyWrap">
        <img src="@/assets/logo.png" alt="RODEO BROS" class="logo">
        <div class="copy">Copyright © 2020 RODEO BROS inc.</div>
        <div
          class="info"
          v-if="$store.state.hostName === 'W'">電話番号：03-5856-5858<br>メールアドレス：rodeo-bidders@aun-wa.com</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      links: [
        {
          title: 'PRODUCT',
          linkList: [
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002705/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000102/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/mens5ba5e1.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/mens5ba5e15.html',
                O: '',
                W: '',
                Q: ''
              },
              name: 'Mens'
            },
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002706/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000103/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/women5ba5e4.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/women5ba5e4.html',
                O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%83%AC%E3%83%87%E3%82%A3%E3%83%BC%E3%82%B9',
                W: '/bep/m/klist2?at=FP&keyword=%83%8C%83f%83B%81%5B%83X&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
                Q: ''
              },
              name: 'Women'
            },
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002772/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000422/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/juniora1a62.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/juniora1a62.html',
                O: 'https://www.aun-wa.com/SHOP/list.cgi?Search=%E3%82%AD%E3%83%83%E3%82%BA',
                W: '/bep/m/klist2?at=FP&keyword=%83L%83b%83Y&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
                Q: ''
              },
              name: 'Junior・Kids'
            },
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000000634/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000361/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/accessorie7.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/accessorie7.html',
                O: 'https://www.aun-wa.com/SHOP/411912/411924/list.html',
                W: '/bep/m/klist2?at=FP&keyword=%83A%83N%83Z%83T%83%8A%81%5B&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
                Q: ''
              },
              name: 'Accessories'
            },
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002910/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000569/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/cap5ba5ada10.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/cap5ba5ada9.html',
                O: 'https://www.aun-wa.com/SHOP/411912/411913/list.html',
                W: '/bep/m/klist2?at=FP&keyword=%83L%83%83%83b%83v&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
                Q: ''
              },
              name: 'Cap'
            },
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002703/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000362/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/bag5ba5d0a10.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/bag5ba5d0a10.html',
                O: 'https://www.aun-wa.com/SHOP/411912/411917/list.html',
                W: '/bep/m/klist2?e_scope=O&user=24431709&keyword=%83o%83b%83O',
                Q: ''
              },
              name: 'Bag'
            },
            {
              link: {
                R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002704/',
                R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000363/',
                Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/footwear5b14.html',
                Y2: 'https://store.shopping.yahoo.co.jp/bros/footwear5b14.html',
                O: 'https://www.aun-wa.com/SHOP/411912/412146/list.html',
                W: '/bep/m/klist2?at=FP&keyword=%83X%83J%83W%83%83%83%93&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
                Q: ''
              },
              name: 'Footwear'
            }
          ]
        },
        {
          title: 'CATEGORY',
          linkList: [
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/newarrival.html',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/more/newarrival.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/more/newarrival.html',
                Y2: 'https://shopping.geocities.jp/bros/more/newarrival.html',
                O: 'https://www.aun-wa.com/more/newarrival.html',
                W: 'https://plus.wowma.jp/user/24431709/plus/more/newarrival.html',
                Q: ''
              },
              name: 'New Arrival'
            },
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/rearrival.html',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/more/rearrival.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/more/rearrival.html',
                Y2: 'https://shopping.geocities.jp/bros/more/rearrival.html',
                O: 'https://www.aun-wa.com/more/rearrival.html',
                W: 'https://plus.wowma.jp/user/24431709/plus/more/rearrival.html',
                Q: ''
              },
              name: 'Re Arrival'
            },
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/brandlist1/',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/brandlist1/',
                Y1: 'https://shopping.geocities.jp/rodeobros/brandlist1/',
                Y2: 'https://shopping.geocities.jp/bros/brandlist1/',
                O: 'https://www.aun-wa.com/brandlist1/',
                W: 'https://plus.wowma.jp/user/24431709/plus/brandlist1/',
                Q: ''
              },
              name: 'Brand List'
            },
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/week.html',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/more/week.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/more/week.html',
                Y2: 'https://shopping.geocities.jp/bros/more/week.html',
                O: 'https://www.aun-wa.com/more/week.html',
                W: 'https://plus.wowma.jp/user/24431709/plus/more/week.html',
                Q: ''
              },
              name: 'Weekly Ranking'
            },
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/month.html',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/more/month.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/more/month.html',
                Y2: 'https://shopping.geocities.jp/bros/more/month.html',
                O: 'https://www.aun-wa.com/more/month.html',
                W: 'https://plus.wowma.jp/user/24431709/plus/more/month.html',
                Q: ''
              },
              name: 'Monthly Ranking'
            }
          ]
        },
        {
          title: 'SNS',
          linkList: [
            {
              link: {
                R1: '',
                R2: '',
                Y1: 'https://shopping.geocities.jp/rodeobros/redirect/redirectfacebook.html',
                Y2: 'https://shopping.geocities.jp/bros/redirect/redirectfacebook.html',
                O: 'https://www.facebook.com/Rodeobros',
                W: 'https://www.facebook.com/Rodeobros',
                Q: ''
              },
              name: 'Facebook'
            },
            {
              link: {
                R1: '',
                R2: '',
                Y1: 'https://shopping.geocities.jp/rodeobros/redirect/redirectinstagram.html',
                Y2: 'https://shopping.geocities.jp/bros/redirect/redirectinstagram.html',
                O: 'https://www.instagram.com/rodeo_bros/',
                W: 'https://www.instagram.com/rodeo_bros/',
                Q: ''
              },
              name: 'Instagram'
            },
            {
              link: {
                R1: '',
                R2: '',
                Y1: 'https://shopping.geocities.jp/rodeobros/redirect/redirecttwitter.html',
                Y2: 'https://shopping.geocities.jp/bros/redirect/redirecttwitter.html',
                O: 'https://twitter.com/rodeobros',
                W: 'https://twitter.com/rodeobros',
                Q: ''
              },
              name: 'Twitter'
            }
          ]
        },
        {
          title: 'INFORMATION',
          linkList: [
            {
              link: {
                R1: 'https://review.rakuten.co.jp/shop/4/235529_235529/1.1/',
                R2: 'https://review.rakuten.co.jp/shop/4/330657_330657/1.1/',
                Y1: 'https://shopping.yahoo.co.jp/store_rating/rodeobros/store/review/',
                Y2: 'https://shopping.yahoo.co.jp/store_rating/bros/store/review/',
                O: 'https://www.aun-wa.com/SHOP/rating_list.html',
                W: '/bep/m/kevl10?user=24431709',
                Q: ''
              },
              name: 'Review'
            },
            {
              link: {
                R1: 'https://www.rakuten.co.jp/auc-rodeo/news.html',
                R2: 'https://www.rakuten.co.jp/rodeo-2nd/news.html',
                Y1: 'https://snlweb.shopping.yahoo.co.jp/shp_snl/optin/select/rodeobros',
                Y2: 'https://snlweb.shopping.yahoo.co.jp/shp_snl/optin/select/bros',
                O: 'https://www.aun-wa.com/SHOP/mailmag.html',
                W: '/bep/m/dm40?user=24431709',
                Q: ''
              },
              name: 'Mail Magazine'
            },
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/company/company000.html',
                R2: 'https://www.rakuten.co.jp/rodeo-2nd/info.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/company/',
                Y2: 'https://shopping.geocities.jp/bros/company/',
                O: 'https://www.aun-wa.com/company/',
                W: '/bep/m/kpaax04?l=shp_005&d=/user/24431709/2',
                Q: ''
              },
              name: 'Company'
            }
          ]
        },
        {
          title: 'CONTACT',
          linkList: [
            {
              link: {
                R1: 'mailto:rodeo@aun-wa.com',
                R2: 'mailto:rodeo@aun-wa.com',
                Y1: 'mailto:rodeo@aun-wa.com',
                Y2: 'mailto:rodeo@aun-wa.com',
                O: 'mailto:rodeo@aun-wa.com',
                W: 'mailto:rodeo@aun-wa.com',
                Q: ''
              },
              name: 'E-mail'
            },
            {
              link: {
                R1: null,
                R2: null,
                Y1: null,
                Y2: null,
                O: null,
                W: null,
                Q: null
              },
              name: 'TEL：03-5856-5858'
            },
            {
              link: {
                R1: null,
                R2: null,
                Y1: null,
                Y2: null,
                O: null,
                W: null,
                Q: null
              },
              name: 'FAX：03-5856-5858'
            },
            {
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/rodeomap.html',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/rodeomap.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/rodeomap.html',
                Y2: 'https://shopping.geocities.jp/bros/rodeomap.html',
                O: 'https://www.aun-wa.com/rodeomap.html',
                W: 'https://plus.wowma.jp/user/24431709/plus/rodeomap.html',
                Q: ''
              },
              name: 'Access to a Real Shop'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footerWrap {
  background-color: #fafafa;
}

.linklist {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 70px 14px;

  > li {
    margin: 0 20px;
    width: 155px;
    font-size: 13px;
  }

  .title {
    font-weight: bold;
    border-bottom: 1px solid #777;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
}

.links {
  > li {
    a, span {
      line-height: 1.8;
      padding-bottom: 1px;
      display: block;
      transition: 0.1s;
    }
    a:hover {
      opacity: 0.3;
    }
  }
}

.copyWrap {
  border-top: 1px solid #aaa;
  text-align: center;
  padding: 70px 0;

  .logo {
    width: 220px;
  }

  .copy {
    font-size: 10px;
    padding-top: 7px;
  }

  .info {
    font-size: 10px;
    margin-top: 13px;
    line-height: 1.4;
  }
}
</style>
