<template>
  <div class="switch">
    <div :class="{ current: leftCurrent }" @click="switchTab('left')">{{ leftText }}</div>
    <div :class="{ current: !leftCurrent }" @click="switchTab('right')">{{ rightText }}</div>
  </div>
</template>

<script>
export default {
  name: 'SwitchTab',
  props: {
    leftCurrent: {
      type: Boolean,
      require: true
    },
    storeKey: {
      type: String,
      require: true
    },
    leftText: {
      type: String,
      require: true
    },
    rightText: {
      type: String,
      require: true
    }
  },
  methods: {
    switchTab (activeLocation) {
      if ((this.leftCurrent === false && activeLocation === 'left') || (this.leftCurrent === true && activeLocation === 'right')) {
        this.$store.commit('switchTab', {
          key: this.storeKey
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 370px;
  font-size: 17px;
  color: #888;
  padding: 10px 0;

  > div {
    user-select: none;
    text-align: center;
    padding: 0 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    transition: .1s;

    &:hover {
      color: #444444;
      border-bottom: 1px solid #444444;
    }
  }

  .current {
    color: #444444;
    border-bottom: 1px solid #444444;
    cursor: default;
  }
}
</style>
