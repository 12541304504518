import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js'
import 'reset-css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import mixins from './mixins/globalFunctions'

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)

Vue.mixin(mixins)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
