<template>
  <div class="wrap">
    <SectionTitle text="Brand" class="title"/>

    <SwitchTab
      :leftCurrent="$store.state.switchTab.brand"
      storeKey="brand"
      leftText="Pick Up"
      rightText="Ranking"/>

    <ul class="brandList minContainer" v-show="$store.state.switchTab.brand">
      <li
        v-for="(item, index) in brandList"
        :key="'ranking' + index">
        <a :href="item[$store.state.hostName]">
          <img :src="item.imageURL" alt="">
        </a>
      </li>
    </ul>
    <ul class="brandList minContainer" v-show="!$store.state.switchTab.brand">
      <li
        v-for="(item, index) in brandRanking"
        :key="'pickup' + index"
        class="ranking">
        <a :href="item[$store.state.hostName]">
          <span></span>
          <span></span>
          <span></span>
          <img :src="item.img" alt="">
        </a>
      </li>
    </ul>

    <MoreLink
      :href="switchURL('brandlist1/')"
      text="ALL"
      v-show="$store.state.switchTab.brand"
      v-if="$store.state.hostName !== 'Q'"/>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'
import SwitchTab from '@/components/SwitchTab.vue'
import MoreLink from '@/components/MoreLink.vue'

export default {
  name: 'Brand',
  computed: {
    brandList () {
      return this.$store.state.json.brand.slice(0, 36)
    },
    brandRanking () {
      return this.$store.state.json.brandRanking.slice(0, 36)
    }
  },
  components: {
    MoreLink,
    SectionTitle,
    SwitchTab
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 120px;
}

.title {
  padding: 80px 0 25px;
}

.brandList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1152px;
  margin: 0 auto;

  > li {
    text-align: center;
    width: 11.111%;

    &.ranking {
      span {
        display: none;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        span {
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          left: 20%;

          &:nth-child(1) {
            top: 12px;
            border-top: 15px solid transparent;
            border-right: 9px solid transparent;
            border-left: 9px solid transparent;
            border-bottom: 15px solid transparent;
          }
          &:nth-child(2) {
            top: 30px;
            border-top: 6px solid transparent;
            border-right: 9px solid transparent;
            border-left: 9px solid transparent;
            border-bottom: 6px solid transparent;
          }
          &:nth-child(3) {
            top: 30px;
            border-top: 6px solid transparent;
            border-right: 9px solid transparent;
            border-left: 9px solid transparent;
            border-bottom: 6px solid transparent;
          }
        }
      }

      &:nth-child(1) {
        span {
          &:nth-child(1) {
            border-bottom-color: #ecc913;
          }
          &:nth-child(2) {
            border-left-color: #ecc913;
            border-bottom-color: #ecc913;
          }
          &:nth-child(3) {
            border-right-color: #ecc913;
            border-bottom-color: #ecc913;
          }
        }
      }

      &:nth-child(2) {
        span {
          &:nth-child(1) {
            border-bottom-color: #a2a2a2;
          }
          &:nth-child(2) {
            border-left-color: #a2a2a2;
            border-bottom-color: #a2a2a2;
          }
          &:nth-child(3) {
            border-right-color: #a2a2a2;
            border-bottom-color: #a2a2a2;
          }
        }
      }

      &:nth-child(3) {
        span {
          &:nth-child(1) {
            border-bottom-color: #983a05;
          }
          &:nth-child(2) {
            border-left-color: #983a05;
            border-bottom-color: #983a05;
          }
          &:nth-child(3) {
            border-right-color: #983a05;
            border-bottom-color: #983a05;
          }
        }
      }
    }

    a {
      display: block;
      padding: 28px 0;
      position: relative;

      &:hover {
        opacity: 0.5;
        transition: 0.2s;
      }
    }
  }

  img {
    width: 60%;
  }
}
</style>
