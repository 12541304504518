<template>
  <header>
    <div class="header">
      <div class="searchWrap container">
        <div
          class="sns"
          v-if="!($store.state.hostName === 'R1' || $store.state.hostName === 'R2' || $store.state.hostName === 'Q')">
          <a
          v-for="s in sns"
          :href="s.link[$store.state.hostName]"
          :key="s.className">
            <div><i class="fa" :class="s.className" aria-hidden="true"></i></div>
          </a>
        </div>
        <div class="search">
          <!-- <div class="text">注目ワード：subu サンダル バッグ FOG RVCA</div> -->
          <form accept-charset="euc-jp" method="get" action="https://esearch.rakuten.co.jp/rms/sd/esearch/vc" v-if="$store.state.hostName === 'R1'">
            <input type="text" name="sitem" placeholder=" Search">
            <input type="hidden" name="sv" value="6">
            <input type="hidden" name="sid" value="235529">
            <input type="hidden" name="su" value="auc-rodeo">
            <input type="hidden" name="sn" value="ロデオブロスRODEO BROS">
            <input type="hidden" name="f" value="A">
            <input type="submit" name="検索" onClick="org=document.charset; document.charset='EUC-JP'; document.sitem.submit(); document.sv.submit(); document.sid.submit(); document.su.submit(); document.sn.submit(); document.f.submit(); document.charset=org;" value="">
          </form>

          <form accept-charset="euc-jp" method="get" action="https://esearch.rakuten.co.jp/rms/sd/esearch/vc" v-if="$store.state.hostName === 'R2'">
            <input type="text" name="sitem" placeholder=" Search">
            <input type="hidden" name="sv" value="6">
            <input type="hidden" name="sid" value="330657">
            <input type="hidden" name="su" value="rodeo-2nd">
            <input type="hidden" name="sn" value="RODEO BROS 2nd">
            <input type="hidden" name="f" value="A">
            <input type="submit" name="検索" onClick="org=document.charset; document.charset='EUC-JP'; document.sitem.submit(); document.sv.submit(); document.sid.submit(); document.su.submit(); document.sn.submit(); document.f.submit(); document.charset=org;" value="">
          </form>

          <form target="_top" method="get" action="https://store.shopping.yahoo.co.jp/rodeobros/search.html" v-if="$store.state.hostName === 'Y1'">
            <input type="text" name="p" placeholder=" Search">
            <input type="submit" name="検索" value="">
          </form>

          <form target="_top" method="get" action="https://store.shopping.yahoo.co.jp/bros/search.html" v-if="$store.state.hostName === 'Y2'">
            <input type="text" name="p" placeholder=" Search">
            <input type="submit" name="検索" value="">
          </form>

          <form method="get" action="https://www.aun-wa.com/SHOP/list.cgi" target="_top" v-if="$store.state.hostName === 'O'">
            <input type="text" name="Search" placeholder=" Search">
            <input type="submit" value="">
          </form>

          <form method="get" accept-charset="Shift_JIS" action="/bep/m/klist2" v-if="$store.state.hostName === 'W'">
            <input type="hidden" name="e_scope" value="O">
            <input type="hidden" name="user" value="24431709">
            <input type="text" name="keyword" value="" placeholder=" Search">
            <input value="" type="submit">
          </form>
        </div>
      </div>

      <div class="menuWrap">
        <ul class="menu">
          <li class="logo">
            <img
              src="https://www.rakuten.ne.jp/gold/rodeo-2nd/top/img/logo2nd.png"
              class="second"
              alt=""
              v-if="$store.state.hostName === 'R2' || $store.state.hostName === 'Y2'">
            <img
              src="https://www.rakuten.ne.jp/gold/auc-rodeo/topFile/img/logo.png"
              alt=""
              v-else>
          </li>
          <li
            v-for="(mainItem, index) in menu"
            :key="mainItem.name"
            class="mainItem"
            @mouseover="openMenu(index, menu)"
            @mouseleave="closeMenu(index, menu)"
            v-show="mainItem.name !== 'BRAND' || $store.state.hostName !== 'Q'"><!-- Qoo10はブランドを削除 -->
            <a :href="mainItem.link[$store.state.hostName]" v-if="mainItem.link">
              {{ mainItem.name }}
            </a>
            <span v-else>
              {{ mainItem.name }}
              <transition name="pullDown">
                <div class="subItemWrap" v-show="mainItem.opened">
                  <ul class="subItem minContainer" v-if="mainItem.name === 'CATEGORY'">
                    <li v-for="subItem in mainItem.sub[$store.state.hostName]" :key="subItem.name">
                      <a :href="subItem.link">{{ subItem.name }}</a>
                    </li>
                  </ul>
                  <ul class="subItem minContainer" v-if="mainItem.name === 'INFO'">
                    <li
                      v-for="subItem in mainItem.sub"
                      :key="subItem.name"
                      v-show="subItem.link[$store.state.hostName]">
                      <a :href="subItem.link[$store.state.hostName]">{{ subItem.name }}</a>
                    </li>
                  </ul>
                </div>
              </transition>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import categoryJson from '@/assets/category.json'

export default {
  name: 'Header',
  data () {
    return {
      sns: [
        {
          className: 'fa-twitter',
          link: {
            R1: '',
            R2: '',
            Y1: 'https://shopping.geocities.jp/rodeobros/redirect/redirecttwitter.html',
            Y2: 'https://shopping.geocities.jp/bros/redirect/redirecttwitter.html',
            O: 'https://twitter.com/rodeobros',
            W: 'https://twitter.com/rodeobros',
            Q: ''
          }
        },
        {
          className: 'fa-facebook-official',
          link: {
            R1: '',
            R2: '',
            Y1: 'https://shopping.geocities.jp/rodeobros/redirect/redirectfacebook.html',
            Y2: 'https://shopping.geocities.jp/bros/redirect/redirectfacebook.html',
            O: 'https://www.facebook.com/Rodeobros',
            W: 'https://www.facebook.com/Rodeobros',
            Q: ''
          }
        },
        {
          className: 'fa-instagram',
          link: {
            R1: '',
            R2: '',
            Y1: 'https://shopping.geocities.jp/rodeobros/redirect/redirectinstagram.html',
            Y2: 'https://shopping.geocities.jp/bros/redirect/redirectinstagram.html',
            O: 'https://www.instagram.com/rodeo_bros/',
            W: 'https://www.instagram.com/rodeo_bros/',
            Q: ''
          }
        }
      ],
      menu: [
        {
          name: 'CATEGORY',
          opened: false,
          sub: categoryJson
        },
        {
          name: 'BRAND',
          link: {
            R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/brandlist1/',
            R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/brandlist1/',
            Y1: 'https://shopping.geocities.jp/rodeobros/brandlist1/',
            Y2: 'https://shopping.geocities.jp/bros/brandlist1/',
            O: 'https://www.aun-wa.com/brandlist1/',
            W: 'https://plus.wowma.jp/user/24431709/plus/brandlist1/',
            Q: ''
          }
        },
        {
          name: 'NEW ARRIVAL',
          link: {
            R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/more/newarrival.html',
            R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/more/newarrival.html',
            Y1: 'https://shopping.geocities.jp/rodeobros/more/newarrival.html',
            Y2: 'https://shopping.geocities.jp/bros/more/newarrival.html',
            O: 'https://www.aun-wa.com/more/newarrival.html',
            W: 'https://plus.wowma.jp/user/24431709/plus/more/newarrival.html',
            Q: 'https://www.qoo10.jp/shop/RODEO-BROS?minishop_bar_onoff=N&keyword_hist=&sell_coupon_cust_no=nEBWLVMMrHp1aCZrWC1CqA%3D%3D&SellerCooponDisplay=Y&sell_cust_no=nEBWLVMMrHp1aCZrWC1CqA%3D%3D&theme_sid=0&page_type=&global_yn=N&frame_id=&qid=0&search_mode=&fbidx=-1&brandno=&group_code=&gdlc_cd=&gdmc_cd=&gdsc_cd=&delivery_group_no=&sortType=SORT_GD_NO&dispType=UIG4&flt_pri_idx=&filterDelivery=NNNNNANNNN&search_global_yn=N&basis=&shipFromNation=&shipto=ALL&SearchNationCode=&is_research_yn=Y&hid_keyword=&video_goods_yn=&coupon_filter_no=&gd_type=&drugs_type=&promotion_yn=&priceMin=&priceMax=&curPage=1&pageSize=60&partial=on&brandno=&paging_value=2#div_section_filter'
          }
        },
        {
          name: 'PRE ORDER',
          link: {
            R1: 'https://item.rakuten.co.jp/auc-rodeo/c/0000002690/',
            R2: 'https://item.rakuten.co.jp/rodeo-2nd/c/0000000216/',
            Y1: 'https://store.shopping.yahoo.co.jp/rodeobros/preorder5b.html',
            Y2: 'https://store.shopping.yahoo.co.jp/bros/preorder5b.html',
            O: 'https://www.aun-wa.com/SHOP/list.php?Search=%E4%BA%88%E7%B4%84&Type=01&PAGE=1',
            W: 'https://wowma.jp/bep/m/klist2?at=FP&keyword=%97%5C%96%F1&user=24431709&e_scope=O&srm=Y&sort1=start%2CD',
            Q: 'https://www.qoo10.jp/shop/RODEO-BROS?keyword=%E4%BA%88%E7%B4%84&keyword_hist=&dispType=GALLERY4'
          }
        },
        {
          name: 'INFO',
          opened: false,
          sub: [
            {
              name: 'REVIEW',
              link: {
                R1: 'https://review.rakuten.co.jp/shop/4/235529_235529/1.1/',
                R2: 'https://review.rakuten.co.jp/shop/4/330657_330657/1.1/',
                Y1: 'https://shopping.yahoo.co.jp/store_rating/rodeobros/store/review/',
                Y2: 'https://shopping.yahoo.co.jp/store_rating/bros/store/review/',
                O: 'https://www.aun-wa.com/SHOP/rating_list.html',
                W: '/bep/m/kevl10?user=24431709',
                Q: ''
              }
            },
            {
              name: 'SHOPPING GUIDE',
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/faq/',
                R2: 'https://www.rakuten.ne.jp/gold/rodeo-2nd/guide/',
                Y1: 'https://shopping.geocities.jp/rodeobros/faq/',
                Y2: 'https://shopping.geocities.jp/bros/faq/',
                O: 'https://www.aun-wa.com/faq/',
                W: '/bep/m/kpaax04?l=shp_005&d=/user/24431709/2',
                Q: ''
              }
            },
            {
              name: 'MAIL MAGAZINE',
              link: {
                R1: 'https://www.rakuten.co.jp/auc-rodeo/news.html',
                R2: 'https://www.rakuten.co.jp/rodeo-2nd/news.html',
                Y1: 'https://snlweb.shopping.yahoo.co.jp/shp_snl/optin/select/rodeobros',
                Y2: 'https://snlweb.shopping.yahoo.co.jp/shp_snl/optin/select/bros',
                O: 'https://www.aun-wa.com/SHOP/mailmag.html',
                W: '/bep/m/dm40?user=24431709',
                Q: ''
              }
            },
            {
              name: 'COMPANY',
              link: {
                R1: 'https://www.rakuten.ne.jp/gold/auc-rodeo/company/company000.html',
                R2: 'https://www.rakuten.co.jp/rodeo-2nd/info.html',
                Y1: 'https://shopping.geocities.jp/rodeobros/company/',
                Y2: 'https://shopping.geocities.jp/bros/company/',
                O: 'https://www.aun-wa.com/company/',
                W: '/bep/m/kpaax04?l=shp_005&d=/user/24431709/2',
                Q: 'https://www.qoo10.jp/shop-info/RODEO-BROS?global_yn=N'
              }
            },
            {
              name: 'Q & A',
              link: {
                R1: '',
                R2: '',
                Y1: '',
                Y2: '',
                O: '',
                W: '',
                Q: 'https://www.qoo10.jp/shop-qna/RODEO-BROS?global_yn=N'
              }
            },
            {
              name: 'CONTACT',
              link: {
                R1: 'mailto:rodeo@aun-wa.com',
                R2: 'mailto:rodeo@aun-wa.com',
                Y1: 'mailto:rodeo@aun-wa.com',
                Y2: 'mailto:rodeo@aun-wa.com',
                O: 'mailto:info@aun-wa.com',
                W: '',
                Q: 'mailto:rodeo-qoo10@aun-wa.com'
              }
            }
          ]
        }
      ]
    }
  },
  methods: {
    openMenu (index, menu) {
      if (menu[index].sub) {
        menu[index].opened = true
      }
    },
    closeMenu (index, menu) {
      menu[index].opened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 10;
}

.searchWrap {
  position: relative;
  height: 45px;
  max-width: 1200px;
  width: calc(100% - 42px);
  margin: 15px auto 0;

  .sns {
    position: absolute;
    top: 17px;
    left: 20px;
    display: flex;
    width: 75px;
    font-size: 18px;
    justify-content: space-between;

    a {
      transition: .1s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .search {
    position: absolute;
    top: 7px;
    right: 0;
    display: flex;
  }

  .text {
    padding: 11px 12px 0 0;
    font-size: 12px;
  }

  input[type="text"] {
    font-size: 13px;
    border: 1px solid #afafaf;
    height: 30px;
    width: 250px;
    padding-left: 5px;

    &:focus {
      border: 1px solid orange
    }
  }

  input[type="submit"] {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 15px;
    height: 15px;
    opacity: 0.4;
    background: url('../assets/search.png') left top no-repeat;
    background-size: contain
  }
}

.menuWrap {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #ddd;
}

.menu {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 14px;
  margin: 0 auto;
  position: relative;

  > li {
    > a,
    > span {
      display: block;
      border-bottom: 1px solid transparent;
      transition: .2s;

      &:hover {
        border-bottom: 1px solid #000;
      }
    }
  }

  .logo {
    width: 205px;
    position: relative;

    img {
      width: 190px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: 0;

      &.second {
        padding-left: 8px;
      }
    }
  }

  .mainItem {
    width: calc((100% - 205px) / 5);
    max-width: 199px;
    line-height: 90px;
    font-size: 14px;
  }

  .subItemWrap {
    position: absolute;
    top: 91px;
    left: 0;
    width: 100%;
    background-color: #f8f8f8;
    overflow: hidden;
  }

  .subItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 80px;
    max-width: 1200px;
    margin: 0 auto;

    > li {
      > a {
        display: block;
        padding: 0 27px;
        transition: .2s;
        font-size: 13px;

        &:hover {
          text-decoration: underline;
          opacity: .5;
        }
      }
    }
  }

  .thirdItem {
    display: flex;
    justify-content: center;
    background-color: #f1f1f1;
    width: 100%;
    position: absolute;
    line-height: 80px;
    top: 80px;
    left: 0;

    > li {
      > a {
        display: block;
        padding: 0 27px;
        transition: .2s;
        font-size: 13px;

        &:hover {
          text-decoration: underline;
          opacity: .5;
        }
      }
    }
  }
}

.pullDown-enter-active, .pullDown-leave-active {
  transition: 0.4s;
  height: auto;
}

.pullDown-enter, .pullDown-leave-to {
  height: 0;
}
</style>
