import Vue from 'vue'
import Vuex from 'vuex'
const axios = require('axios')
const _ = require('lodash')

Vue.use(Vuex)

/**
 * ホスト名から、今どのサイトなのかを決定する
 * R1, R2, Y1, Y2, O, Q, Wのいずれかの値が入る
 */
let hostName = ''
if (location.host === 'www.rakuten.ne.jp') {
  if (location.href.indexOf('qoo10') !== -1) {
    hostName = 'Q'
  } else if (location.href.indexOf('auc-rodeo') !== -1) {
    hostName = 'R1'
  } else if (location.href.indexOf('rodeo-2nd') !== -1) {
    hostName = 'R2'
  }
} else if (location.host === 'shopping.geocities.jp') {
  if (location.href.indexOf('rodeobros') !== -1) {
    hostName = 'Y1'
  } else {
    hostName = 'Y2'
  }
} else if (location.host === 'www.aun-wa.com') {
  hostName = 'O'
} else if (location.host === 'plus.wowma.jp') {
  hostName = 'W'
} else {
  hostName = 'R1'
  // hostName = 'R2'
  // hostName = 'Y1'
  // hostName = 'Y2'
  // hostName = 'O'
  // hostName = 'Q'
  // hostName = 'W'
  console.log('テストサーバー、またはホストの解決ができません。')
  console.log('仮設定：' + hostName)
}

/**
 * ブランドJSONのキーが扱いづらいため修正
 *
 * @param {array} brandJson - ブランドのJSON
 */
const renameKeyBrand = (brandJson) => {
  return brandJson.map(item => {
    return _.mapKeys(item, (val, key) => {
      key = key.replace('pageURL', '')
      if (key === 'R') key = 'R1'
      else if (key === 'Y') key = 'Y1'
      return key
    })
  })
}

/**
 * ランキングブランドJSONのキーが扱いづらいため修正
 *
 * @param {array} rankingBrandJson - ランキングブランドのJSON
 */
const renameKeyRankingBrand = (rankingBrandJson) => {
  return rankingBrandJson.map(item => {
    return _.mapKeys(item, (val, key) => {
      key = key.replace('link', '')
      if (key === 'R') key = 'R1'
      else if (key === 'Y') key = 'Y1'
      else if (key === 'S') key = 'O'
      return key
    })
  })
}

/**
 * axisonのgetを抽象化した関数
 * @param {String} fileName - getしたいファイル名
 * @param {function} commit - actionsの引数からもらう関数
 * @param {function} keyName - stateで使うキーの文字列
 */
const getJsonAsync = (fileName, commit, keyName) => {
  axios.get(`./jsonAll/${fileName}.json`)
    .then(function (response) {
      let tmpResponse = response.data

      if (fileName === 'data') {
        tmpResponse = renameKeyBrand(tmpResponse)
      } else if (fileName === 'rankingBrand') {
        tmpResponse = renameKeyRankingBrand(tmpResponse)
      }

      commit('setJson', {
        json: tmpResponse,
        key: keyName
      })
    })
    .catch(function (error) {
      console.log(fileName + '.jsonが見つかりませんでした。' + error)
    })
}

export default new Vuex.Store({
  state: {
    hostName: hostName,
    switchTab: {
      brand: true,
      pickUp: true
    },
    swiperCommonOptions: {
      loop: true,
      speed: 900,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    json: {
      banner: [],
      new: [],
      week: [],
      brand: [],
      brandRanking: []
    }
  },
  mutations: {
    switchTab (state, data) {
      state.switchTab[data.key] = !state.switchTab[data.key]
    },
    setJson (state, data) {
      state.json[data.key] = data.json
    }
  },
  actions: {
    getJsons ({ commit }) {
      getJsonAsync('topbanner', commit, 'banner')
      getJsonAsync('newNewArrival', commit, 'new')
      getJsonAsync('rankingWeek', commit, 'week')
      getJsonAsync('rankingBrand', commit, 'brandRanking')
      getJsonAsync('data', commit, 'brand')
    }
  }
})
