<template>
  <a :href="href" class="more">{{ text }}</a>
</template>

<script>
export default {
  name: 'MoreLink',
  props: {
    href: {
      type: String,
      require: true
    },
    text: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
.more {
  display: block;
  margin: 0 auto;
  color: #888;
  font-size: 18px;
  line-height: 40px;
  width: 100px;
  text-align: center;
  margin: 0 auto;
  border-bottom: 1px solid #888;

  &:hover {
    opacity: 0.6;
    transition: .5s;
  }
}
</style>
