<template>
  <div class="wrap">
    <SectionTitle text="New Arrival" class="title"/>

    <ul class="newArrival minContainer">
      <li v-for="(item, index) in newArrival" :key="index">
        <a
          :href="switchItemURL(item)"
          @mouseover="itemMouseOver(item, index)"
          @mouseout="itemMouseOut(item, index)"
          ref="items">
          <img
            :src="`https://thumbnail.image.rakuten.co.jp/@0_mall/auc-rodeo/cabinet/${item.image}-0.jpg`"
            :data-second-thumb="item.second_thumb"
            alt=""
            class="img"
            v-show="!item.gif"
            ref="itemImages">
          <img
            :src="`https://thumbnail.image.rakuten.co.jp/@0_mall/auc-rodeo/cabinet/${item.image}-0.gif`"
            alt=""
            class="img"
            v-if="item.gif">
          <div class="brand">{{ item.brand }}</div>
          <div class="price">{{ taxIn(item.price) }}</div>
        </a>
      </li>
    </ul>

    <MoreLink :href="switchURL('more/newarrival.html')" text="MORE"/>
  </div>
</template>

<script>
import MoreLink from '@/components/MoreLink.vue'
import SectionTitle from '@/components/SectionTitle.vue'

export default {
  name: 'NewArrival',
  computed: {
    newArrival () {
      const rtn = []
      const newJson = this.$store.state.json.new
      for (let i = 0; i < newJson.length; i++) {
        if (newJson[i].group === 'NEW') {
          rtn.push(newJson[i])
        }
        if (rtn.length === 24) break
      }
      return rtn
    }
  },
  methods: {
    itemMouseOver (item, index) {
      if (!item.gif) {
        const imgDom = this.$refs.itemImages[index]
        imgDom.src = imgDom.src.replace('-0.jpg', `-${imgDom.dataset.secondThumb}.jpg`)
      }
    },
    itemMouseOut (item, index) {
      if (!item.gif) {
        const imgDom = this.$refs.itemImages[index]
        imgDom.src = imgDom.src.replace(`-${imgDom.dataset.secondThumb}.jpg`, '-0.jpg')
      }
    }
  },
  components: {
    MoreLink,
    SectionTitle
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 70px 0 115px;
}

.title {
  padding: 40px 0 70px;
}

.newArrival {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1104px;
  margin: 0 auto;

  > li {
    width: 24.3%;
    margin-bottom: 82px;
    text-align: center;
    font-size: 13px;
  }

  .img {
    width: 100%;
  }

  .brand {
    padding: 21px 0 6px;
  }
}
</style>
