<template>
  <div class="wrap">
    <SectionTitle text="Ranking"/>

    <ul class="gridRanking minContainer">
      <li v-for="(item, index) in weeklyRanking" :key="index">
        <a :href="switchItemURL(item)">
          <div class="rank">{{ index + 1 }}</div>
          <img
            :src="`https://thumbnail.image.rakuten.co.jp/@0_mall/auc-rodeo/cabinet/${item.image}-0.jpg`"
            alt=""
            class="img">
          <div class="textWrap">
            <div class="brand">{{ item.brand }}</div>
            <div class="price">{{ taxIn(item.price) }}</div>
          </div>
        </a>
      </li>
    </ul>

    <!-- <div class="minContainer">
      <ItemSlider
        :items="weeklyRanking"
        class="itemSlider"
        :rank="true"/>
    </div> -->

    <MoreLink
      :href="switchURL('more/week.html')"
      text="MORE"/>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'
// import ItemSlider from '@/components/ItemSlider.vue'
import MoreLink from '@/components/MoreLink.vue'

export default {
  name: 'Ranking',
  computed: {
    weeklyRanking () {
      return this.$store.state.json.week.slice(0, 9)
    }
  },
  components: {
    MoreLink,
    SectionTitle
    // ItemSlider
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 50px 0 100px;
}

.gridRanking {
  margin: 15px auto 30px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;

  > li {
    a {
      transition: .2s;
      position: relative;
      display: block;

      &:hover {
        opacity: .8;
      }
    }

    &:nth-child(1) {
      grid-column: 1/3;
      grid-row: 1/3;
    }

    &:nth-child(6) {
      grid-column: 4/6;
      grid-row: 2/4;
    }

    .rank {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      line-height: 19px;
      text-align: center;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      color: #969696;
      border-radius: 100%;
      font-size: 12px;
    }

    &:nth-child(1) .rank {
      color: #e0ce65;
      border: 1px solid #e0ce65;
    }

    &:nth-child(2) .rank {
      color: #afafaf;
      border: 1px solid #c4c4c4;
    }

    &:nth-child(3) .rank {
      color: #d8a794;
      border: 1px solid #d8a794;
    }

    .img {
      vertical-align: bottom;
    }

    .textWrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      background-color: rgba(0,0,0,.3);
      padding: 4.7% 6% 4.7%;
      color: #fff;
    }

    .brand {
      font-size: 13px;
      margin-bottom: 5px;
    }

    .price {
      font-size: 13px;
    }
  }

  .img {
    width: 100%;
  }
}
</style>
