<template>
  <div class="wrap">
    <SectionTitle text="Pick Up"/>

    <SwitchTab
      :leftCurrent="$store.state.switchTab.pickUp"
      storeKey="pickUp"
      leftText="Re Arrival"
      rightText="Rising"/>

    <div class="itemSliderWrap minContainer">
      <ItemSlider
        :items="reArrival"
        class="itemSlider"
        :class="{ active: $store.state.switchTab.pickUp }"/>
      <ItemSlider
        :items="hotItems"
        class="itemSlider"
        :class="{ active: !$store.state.switchTab.pickUp }"/>
    </div>

    <MoreLink
      :href="switchURL('more/rearrival.html')"
      text="MORE"
      v-if="$store.state.switchTab.pickUp"/>
    <MoreLink
      :href="switchURL('more/hot.html')"
      text="MORE"
      v-else/>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue'
import SwitchTab from '@/components/SwitchTab.vue'
import ItemSlider from '@/components/ItemSlider.vue'
import MoreLink from '@/components/MoreLink.vue'

export default {
  name: 'PickUp',
  computed: {
    newArrival () {
      return this.$store.state.json.new
    },
    hotItems () {
      const rtn = []
      for (let i = 0; i < this.newArrival.length; i++) {
        if (this.newArrival[i].hot === 1) {
          rtn.push(this.newArrival[i])
        }
        if (rtn.length === 20) break
      }
      return rtn
    },
    reArrival () {
      const rtn = []
      for (let i = 0; i < this.newArrival.length; i++) {
        if (this.newArrival[i].group === '再入荷') {
          rtn.push(this.newArrival[i])
        }
        if (rtn.length === 20) break
      }
      return rtn
    }
  },
  components: {
    MoreLink,
    SectionTitle,
    SwitchTab,
    ItemSlider
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 50px 0 70px;
}

.itemSliderWrap {
  position: relative;
  height: 454px;
}

.itemSlider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
  transition: opacity .2s;
  opacity: 0;

  &.active {
    opacity: 1;
    z-index: 10;
  }
}
</style>
