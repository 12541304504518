<template>
  <div id="app">
    <Header/>
    <Banner/>
    <Brand/>
    <div v-if="$store.state.hostName !== 'Q'">
      <NewArrival/>
      <Ranking/>
      <Category/>
      <PickUp/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Brand from '@/components/Brand.vue'
import NewArrival from '@/components/NewArrival.vue'
import Category from '@/components/Category.vue'
import PickUp from '@/components/PickUp.vue'
import Ranking from '@/components/Ranking.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Banner,
    Brand,
    NewArrival,
    Category,
    PickUp,
    Ranking,
    Footer
  },
  mounted () {
    // 全てのJSONの取得
    this.$store.dispatch('getJsons')
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;1,400&display=swap');

#app {
  font-family: "Crimson Text", "Times New Roman", Times, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", serif;
  color: #666666;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #444;
}

img {
  vertical-align: bottom;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: bottom;
  font-family: "Crimson Text", "Times New Roman", Times, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", serif;

  &::placeholder {
    color: #ccc;
  }
}

.minContainer {
  min-width: 800px;
  padding-right: 14px;
  padding-left: 14px;
}

.container {
  width: 1200px;
  padding: 0 14px;
}

/* ----- Swiper 共通 start ----- */
.swiper-pagination-bullet-active {
  background-color: #666 !important;
}

.swiper-button-prev {
  left: 0 !important;
  transform: rotate(180deg);
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 18px 12px;
  transition: .3s;
}

.swiper-button-hover {
  background-color: rgba(255, 255, 255, 0.33);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
/* Swiper 共通 end */
</style>
